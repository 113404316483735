
import { defineComponent, ref, onMounted } from "vue"
import { Actions } from "@/store/enums/StoreEnums"
import { useStore } from "vuex"
import { useRoute, useRouter } from "vue-router"
import Swal from "sweetalert2/dist/sweetalert2.min.js"
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb"
import type { ElForm } from "element-plus"
import type { ElUpload, ElInput } from "element-plus"
import { ShippingOrder } from "@/store/modules/ShippingOrderModule"

type FormInstance = InstanceType<typeof ElForm>;

export default defineComponent({
  components: {
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("发货编辑", ["发货管理;shipping_orders", route.params.id.toString()])
    })

    const rules = ref({
      name: [
        {
          required: true,
          message: "收件人姓名不可为空",
          trigger: "change",
        },
      ],
      mobile: [
        {
          required: true,
          message: "收件人手机号码不可为空",
          trigger: "change",
        },
      ],
      area: [
        {
          required: true,
          message: "收件人地区不可为空",
          trigger: "change",
        },
      ],
      address_detail: [
        {
          required: true,
          message: "收件人详细地址不可为空",
          trigger: "change",
        },
      ]
    })

    const formRef = ref<FormInstance>()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const shipping_order = ref({} as ShippingOrder)

    const onCancel = () => {
      router.push({ name: "shipping_orders" });
    };

    const onSubmit = (formEl: FormInstance | undefined) => {
      if (!formEl) return

      formEl.validate((valid) => {
        if (valid) {
          store
            .dispatch(Actions.UPDATE_SHIPPING_ORDER, {
              id: route.params.id,
              values: shipping_order.value,
            })
            .then(() => {
              router.push({ name: "shipping_orders" });
            })
            .catch(() => {
              // const [error] = Object.keys(store.getters.getErrors);
              // Swal.fire({
              //   text: store.getters.getErrors[error],
              //   icon: "error",
              //   buttonsStyling: false,
              //   confirmButtonText: "Try again!",
              //   customClass: {
              //     confirmButton: "btn fw-bold btn-light-danger",
              //   },
              // });
            })
        }
      })
    }

    store
      .dispatch(Actions.GET_SHIPPING_ORDER, route.params.id)
      .then(() => {
        shipping_order.value = store.getters.currentShippingOrder
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      })

    return {
      onSubmit,
      shipping_order,
      rules,
      onCancel,
      formRef
    }
  }
});
